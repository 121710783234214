define("discourse/plugins/discourse-zoom/discourse/components/webinar-register", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _ajax, _utilities, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const STARTED = "started",
    ENDED = "ended";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("webinar.{status,ends_at}"), _dec2 = (0, _decorators.default)("webinar.{status,ends_at}"), _dec3 = (0, _decorators.default)("currentUser", "webinar.attendees"), _dec4 = (0, _decorators.default)("currentUser", "webinar.host"), _dec5 = (0, _decorators.default)("currentUser", "webinar.panelists"), _dec6 = (0, _decorators.default)("webinar.starts_at", "isAttendee", "registered"), _dec7 = (0, _decorators.default)("isAttendee", "registered"), _dec8 = (0, _decorators.default)("webinar.title"), _dec9 = (0, _decorators.default)("webinar.{starts_at,ends_at}"), _dec10 = (0, _decorators.default)("webinar.{starts_at,ends_at}"), _dec11 = (0, _decorators.default)("webinar.join_url"), (_obj = {
    loading: false,
    registered: (0, _computed.or)("isHost", "isPanelist", "isAttendee"),
    webinarStarted(webinar) {
      const beforeStart = this.siteSettings.zoom_join_x_mins_before_start;
      if (webinar.status === STARTED) {
        if (!beforeStart) {
          return true;
        }
        const newStartTime = moment(webinar.starts_at).subtract(beforeStart, "minutes");
        if (newStartTime.isBefore(moment())) {
          return true;
        }
      }
      return false;
    },
    webinarEnded(webinar) {
      if (webinar.status === ENDED || moment(webinar.ends_at).isBefore(moment())) {
        return true;
      }
      return false;
    },
    isAppWebview() {
      return this.capabilities.isAppWebview;
    },
    isAttendee(user, attendees) {
      if (attendees) {
        for (let attendee of attendees) {
          if (attendee.id === user.id) {
            return true;
          }
        }
      }
      return false;
    },
    isHost(user, host) {
      if (host) {
        return user.id === host.id;
      }
      return false;
    },
    isPanelist(user, panelists) {
      for (let panelist of panelists) {
        if (panelist.id === user.id) {
          return true;
        }
      }
      return false;
    },
    canUnregister(starts_at, isAttendee, registered) {
      if (moment(starts_at).isBefore(moment())) {
        return false;
      }
      return isAttendee && registered;
    },
    userCanRegister(isAttendee, registered) {
      return !isAttendee && !registered;
    },
    toggleRegistration(registering) {
      const method = registering ? "PUT" : "DELETE";
      this.set("loading", true);
      return (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}.json`, {
        type: method
      }).then(() => {
        this.store.find("webinar", this.webinar.id).then(webinar => {
          this.set("webinar", webinar);
        });
        this.set("loading", false);
      }).finally(() => this.set("loading", false));
    },
    downloadName(title) {
      return title + ".ics";
    },
    addToGoogleCalendarUrl(webinar) {
      return `http://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(webinar.title)}&dates=${this.formatDateForGoogleApi(webinar.starts_at)}/${this.formatDateForGoogleApi(webinar.ends_at)}`;
    },
    downloadIcsUrl(webinar) {
      const now = this.formatDateForIcs(new Date());
      return `data:text/calendar;charset=utf-8,` + encodeURIComponent(`BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//hacksw/handcal//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:${now}-${webinar.title}\nDTSTAMP:${now}\nDTSTART:${this.formatDateForIcs(webinar.starts_at)}\nDTEND:${this.formatDateForIcs(webinar.ends_at)}\nSUMMARY:${webinar.title}\nEND:VEVENT\nEND:VCALENDAR`);
    },
    joinViaZoom(joinUrl) {
      if (joinUrl && this.siteSettings.zoom_use_join_url) {
        return joinUrl;
      } else {
        return false;
      }
    },
    formatDateForGoogleApi(date) {
      return new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, "");
    },
    formatDateForIcs(date) {
      return moment(date).utc().format("YYYYMMDDTHHmmss") + "Z";
    },
    actions: {
      register() {
        this.toggleRegistration(true);
      },
      unregister() {
        this.toggleRegistration(false);
      },
      addEventAppWebview() {
        const event = {
          title: this.webinar.title,
          starts_at: this.webinar.starts_at,
          ends_at: this.webinar.ends_at
        };
        (0, _utilities.postRNWebviewMessage)("eventRegistration", JSON.stringify(event));
      },
      joinSDK() {
        const url = this.siteSettings.zoom_enable_sdk_fallback ? `/zoom/webinars/${this.webinar.id}/sdk?fallback=1` : `/zoom/webinars/${this.webinar.id}/sdk`;
        if (this.registered) {
          window.location.href = url;
        } else {
          this.toggleRegistration(true).then(() => {
            window.location.href = url;
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "webinarStarted", [_dec], Object.getOwnPropertyDescriptor(_obj, "webinarStarted"), _obj), _applyDecoratedDescriptor(_obj, "webinarEnded", [_dec2], Object.getOwnPropertyDescriptor(_obj, "webinarEnded"), _obj), _applyDecoratedDescriptor(_obj, "isAppWebview", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "isAppWebview"), _obj), _applyDecoratedDescriptor(_obj, "isAttendee", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isAttendee"), _obj), _applyDecoratedDescriptor(_obj, "isHost", [_dec4], Object.getOwnPropertyDescriptor(_obj, "isHost"), _obj), _applyDecoratedDescriptor(_obj, "isPanelist", [_dec5], Object.getOwnPropertyDescriptor(_obj, "isPanelist"), _obj), _applyDecoratedDescriptor(_obj, "canUnregister", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canUnregister"), _obj), _applyDecoratedDescriptor(_obj, "userCanRegister", [_dec7], Object.getOwnPropertyDescriptor(_obj, "userCanRegister"), _obj), _applyDecoratedDescriptor(_obj, "downloadName", [_dec8], Object.getOwnPropertyDescriptor(_obj, "downloadName"), _obj), _applyDecoratedDescriptor(_obj, "addToGoogleCalendarUrl", [_dec9], Object.getOwnPropertyDescriptor(_obj, "addToGoogleCalendarUrl"), _obj), _applyDecoratedDescriptor(_obj, "downloadIcsUrl", [_dec10], Object.getOwnPropertyDescriptor(_obj, "downloadIcsUrl"), _obj), _applyDecoratedDescriptor(_obj, "joinViaZoom", [_dec11], Object.getOwnPropertyDescriptor(_obj, "joinViaZoom"), _obj)), _obj)));
});